<div class="content-container" [ngClass]="{'hideSidenav': hideSidenav$ | async}">
<!--    <ng-container *ngIf="hideSidenav$ | async; else withSidenav">-->
<!--        <h2>hello world</h2>-->
<!--        <zui-progress-spinner-->
<!--                *ngIf="isLoading$ | async"-->
<!--                class="progress-spinner"-->
<!--                color="accent"-->
<!--                diameter=256-->
<!--        ></zui-progress-spinner>-->
<!--        <router-outlet></router-outlet>-->
<!--    </ng-container>-->
<!--    <ng-template #withSidenav>-->
        <zui-sidenav
            #sidenav
            [sidenavParams]="sidenavConfig"
            [header]="sidenavHeader"
            [footer]="sidenavFooter"
            (emitSelectedCompany)="selectCompany($event)">
            <zui-progress-spinner
                    *ngIf="isLoading$ | async"
                    class="progress-spinner"
                    color="accent"
                    diameter=256
            ></zui-progress-spinner>
            <router-outlet></router-outlet>
        </zui-sidenav>
<!--    </ng-template>-->
    <zui-footer-component></zui-footer-component>
</div>
