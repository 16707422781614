import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { RootState } from 'app/app.module';
import {ACTIVE_ACCOUNT_PARAM, HIDE_SIDENAV_PARAM, LocalStorageService} from 'app/local-storage.service';
import { userDataSelector } from 'app/state/app/app.selectors';
import { UserService } from '../user/user.service';
import {rotateChevron} from "../../app.animations";
import {BehaviorSubject, Subscription} from "rxjs";
import {DispatcherLocationToggleService} from "../../dispatcherLocationToggle.service";

@Component({
	selector: 'header-bar',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
	animations: [rotateChevron]
})

export class HeaderComponent implements OnInit, OnDestroy {

	public userName: string;

	public text: string;

	public companyName: string;

	public headerItems;

	public expanded = false;

	public account = '';

	public showDLComponent$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

	public showLocationFilter$ = new BehaviorSubject(false);

	public hideSidenav;

	dispatcherLocationSubscription: Subscription;
	locationSubscription: Subscription;

	constructor(
		public router: Router,
		public users: UserService,
		private activatedRoute: ActivatedRoute,
		private store: Store<RootState>,
		private localStorageService: LocalStorageService,
		public dispatcherLocationToggleService: DispatcherLocationToggleService,
	) {
		this.account = this.localStorageService.get(ACTIVE_ACCOUNT_PARAM);
		this.hideSidenav = this.localStorageService.get(HIDE_SIDENAV_PARAM);
	}

	ngOnDestroy(){
		this.dispatcherLocationSubscription.unsubscribe();
		this.locationSubscription.unsubscribe();

	}

	ngOnInit() {
		this.headerItems = [
			{
				label: 'Logout',
				routerLink: 'logout'
			}
		];
		this.store.select(userDataSelector).subscribe((user) => {
			this.userName = user.displayName;
			this.companyName = user.companyName;
		});
		this.dispatcherLocationSubscription = this.dispatcherLocationToggleService.getToggleStatus().subscribe(showHide => {
			this.showDLComponent$.next(showHide);
		});
		this.locationSubscription = this.dispatcherLocationToggleService.getLocationFilterStatus().subscribe(showHide => {
			this.showLocationFilter$.next(showHide);
		});
	}

	goToRoute(routeAsString) {
		this.router.navigate(['/' + routeAsString]);
	}
}
